import React from 'react'

function useHandleKeyDown(startListening,stopListening) {
    const handleKeyDown = (
        event: React.KeyboardEvent<HTMLInputElement>,
        fieldName: string,
        isNumber?: boolean,
        type?: string,
        option?: any
      ) => {
        console.log(event.key,fieldName,isNumber,type,option)
        if (event.key === "ArrowUp") {
          startListening(fieldName, isNumber, type, option);
        }
        if (event.key === "ArrowDown") {
          stopListening();
        }
      };


      return {handleKeyDown}
}

export default useHandleKeyDown