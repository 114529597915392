import Checkbox from "components/checkbox/Checkbox";
import InputSelect from "global/InputSelect/InputSelect";
import InputText from "global/InputText/InputText";
import MultiLineText from "global/MultiLineText/MultiLineText";
import { useFetchOptions } from "global/hooks/useFetchOptions";
import { useState } from "react";
import { getUrls } from "services/api.enums";
import { searchByCity } from "tools/functions/Methods";

import { RenderIcon } from "../components/render-icon";
import { useSpeechRecognize } from "../hooks/useSpeech-recognize";
import useSearchCity from "../hooks/useSearchCity";
import useHandleKeyDown from "../hooks/useHandleKeyDown";

const Receiver = ({ formik }) => {
  const { values, setFieldValue, handleChange, errors, touched } = formik;
  const [disableSupport, setDisableSupport] = useState(true);
  const { dataOptions: contentType } = useFetchOptions(getUrls.findByCity);
  const { handleChangeCity } = useSearchCity(
    setFieldValue,
    setDisableSupport,
    contentType?.options
  );
  const { startListening, stopListening, isListening } = useSpeechRecognize(
    setFieldValue,
    handleChange,
    handleChangeCity
  );

  const {handleKeyDown}=useHandleKeyDown(startListening,stopListening)


  return (
    <>
      <h1 className=" text-lg font-semibold">اطلاعات گیرنده</h1>
      <div className="grid grid-cols-3 Max-md:grid-cols-1 items-center max-w-6xl Max-md:flex-col gap-x-4 gap-y-4 mt-6 mb-4 ">
        <InputText
          label="نام و نام خانوادگی"
          name="receiver.name"
          handleChange={handleChange}
          values={values.receiver.name}
          important
          error={touched?.receiver?.name && errors.receiver?.name}
          wrapperClassName="w-full"
          classNames="bg-white"
          leftIcon={
            <RenderIcon
              isListening={isListening}
              startListening={() => startListening("receiver.name")}
              stopListening={stopListening}
            />
          }
          handleKeyDown={(e) => handleKeyDown(e, "receiver.name")}
        />

        <InputText
          dir="ltr"
          label="موبایل"
          name="receiver.mobile"
          handleChange={handleChange}
          values={values.receiver.mobile}
          important
          error={touched?.receiver?.mobile && errors?.receiver?.mobile}
          wrapperClassName="w-full"
          classNames="bg-white"
          maxLength={11}
          leftIcon={
            <RenderIcon
              isListening={isListening}
              startListening={() => startListening("receiver.mobile", true)}
              stopListening={stopListening}
            />
          }
          handleKeyDown={(e) => handleKeyDown(e, "receiver.mobile", true)}
        />

        <InputSelect
          handleKeyDown={(e) =>
            handleKeyDown(
              e,
              "receiver.receiverCityId",
              false,
              "object",
              contentType?.options
            )
          }
          label="شهر مقصد"
          important
          name="receiver.receiverCityId"
          handleChange={(name, value) => handleChangeCity(name, value)}
          values={values.receiver.receiverCityId}
          error={
            touched.receiver?.receiverCityId && errors.receiver?.receiverCityId
          }
          options={contentType?.options}
          wrapperClassName="w-full"
          customFilter={searchByCity}
          icon={
            <RenderIcon
              isListening={isListening}
              startListening={() =>
                startListening(
                  "receiver.receiverCityId",
                  false,
                  "object",
                  contentType?.options
                  
                )
              }
              stopListening={stopListening}
            />
          }
        />

        <MultiLineText
          label="آدرس"
          important
          values={values.receiver.address}
          name="receiver.address"
          handleChange={handleChange}
          error={touched.receiver?.address && errors.receiver?.address}
          classNames="bg-white"
          wrapperClassName="col-span-2 Max-md:col-span-1 relative"
          row={2}
          icon={
            <RenderIcon
              isListening={isListening}
              startListening={() => startListening("receiver.address")}
              stopListening={stopListening}
            />
          }
          handleKeyDown={(e) => handleKeyDown(e, "receiver.address")}
        />

        <Checkbox
          isDisabled={disableSupport === false ?? true}
          WrapperClassName=""
          handleChange={(e) => {
            setFieldValue("thirdPartyDelivery", e.target.checked);
            setFieldValue("cod", false);
            setFieldValue("postBack", false);
          }}
          name="thirdPartyDelivery"
          values={values.thirdPartyDelivery}
          title="بخش تابعه، توزیع غیرباکسیت"
          labelClassName={!disableSupport ? " !text-red" : ""}
          className={!disableSupport ? " !text-red" : ""}
        />
      </div>
    </>
  );
};

export default Receiver;
