import { useEffect, useState } from "react";

export const useSpeechRecognize = (
  setFieldValue,
  handleChange?: any,
  handleChangeCity?: any
) => {
  const [recognition, setRecognition] = useState(null);
  const [isListening, setIsListening] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const SpeechRecognition =
        (window as any).SpeechRecognition ||
        (window as any).webkitSpeechRecognition;
      if (SpeechRecognition) {
        const recognitionInstance = new SpeechRecognition();
        recognitionInstance.lang = "fa-IR"; // Set language
        recognitionInstance.interimResults = true; // Enable interim results
        setRecognition(recognitionInstance);
      } else {
        alert("Speech recognition is not supported in this browser.");
      }
    }
  }, []);

  const startListening = (
    fieldName: string,
    isNumber?: boolean,
    fieldType = "string",
    options = undefined
  ) => {
    if (!recognition || isListening) return;

    recognition.start();
    setIsListening(true);
    recognition.onresult = (event) => {
      let transcript = event.results[0][0].transcript.trim();

      if (isNumber) {
        transcript = persianToEnglish(transcript);
        transcript = transcript.replace(/\s+/g, "");
      }

      if (fieldType === "object") {
        // const matched = options.find((item) => item.text.includes(transcript));
        const matched = searchAfterDash(transcript,options);
        handleChange = handleChangeCity(fieldName, {
          id: matched[0]?.id,
          text: matched[0]?.text,
        });
      } else {
        setFieldValue(fieldName, transcript);
      }
    };

    recognition.onerror = (event) => {
      console.error("Speech Recognition Error:", event.error);
    };

    recognition.onend = () => {
      setIsListening(false);
    };
  };

  const stopListening = () => {
    if (recognition && isListening) {
      recognition.stop();

      setIsListening(false);
    }
  };
  return { startListening, stopListening, isListening };
};

const persianToEnglish = (text) => {
  const persianDigits = [
    /۰/g,
    /۱/g,
    /۲/g,
    /۳/g,
    /۴/g,
    /۵/g,
    /۶/g,
    /۷/g,
    /۸/g,
    /۹/g,
  ];
  const englishDigits = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

  persianDigits.forEach((regex, index) => {
    text = text.replace(regex, englishDigits[index]);
  });

  return text;
};

function searchAfterDash(searchTerm, list) {

  return list.filter((location) => {
    const [_, afterDash] = location.text.split(" - ");
    return afterDash.trim().includes(searchTerm);
  });
}
